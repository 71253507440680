<template>
  <v-container fluid>
    <v-card flat>
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5, 10, 15, 20],
          'show-current-page': true,
          'show-first-last-page': true,
        }"
        :headers="headers"
        :items="firmwares"
        :loading="loading"
        :options.sync="options"
        :search="search"
        :server-items-length="getFirmwaresTotalCount"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar color="indigo lighten-5" flat>
            <BackButton />
            <v-toolbar-title>Список ПЗ пристроїв</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              class="shrink mr-10"
              hide-details
              label="Пошук"
              single-line
            ></v-text-field>
            <v-dialog v-model="dialog" max-width="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs" v-on="on"> Додати ПЗ</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model="editedItem.device_type"
                          :error-messages="inputErrors('device_type')"
                          :items="machinesTypesDictionary"
                          disabled
                          item-text="description"
                          item-value="value"
                          label="Тип пристрою"
                          @blur="$v.editedItem.device_type.$touch()"
                          @change="(value) => (isDeviceTypeTerminal = value === 101)"
                          @input="$v.editedItem.device_type.$touch()"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model="editedItem.terminal_model"
                          :error-messages="inputErrors('terminal_model')"
                          :items="terminalsModelsDictionary"
                          :label="isDeviceTypeTerminal ? 'Модель терминала' : 'Модель плати автомата'"
                          item-text="description"
                          item-value="name"
                          @blur="$v.editedItem.terminal_model.$touch()"
                          @input="$v.editedItem.terminal_model.$touch()"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.version_name"
                          :error-messages="inputErrors('version_name')"
                          label="Версія ПЗ"
                          @blur="$v.editedItem.version_name.$touch()"
                          @input="$v.editedItem.version_name.$touch()"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.description" label="Опис для користувача" />
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <PagingSelect
                          :value="editedItem.available_to_update"
                          :options="firmwaresForSearch"
                          :options-params="firmwareVersionsOptions"
                          :disabled="!editedItem.is_available"
                          label="На яку версію ПЗ термінала ставити"
                          item-value="id"
                          item-text="version_name"
                          multiple
                          @change="(value) => (editedItem.available_to_update = value)"
                        >
                          <template v-slot:item="{ item }">{{ item.version_name }} (#{{ item.id }})</template>
                        </PagingSelect>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model="editedItem.supported_devices"
                          :items="firmwareDevicesTypes"
                          :error-messages="inputErrors('supported_devices')"
                          item-text="description"
                          item-value="name"
                          label="Які типи автоматів ПЗ підтримує"
                          :outlined="false"
                          multiple
                          @blur="$v.editedItem.supported_devices.$touch()"
                          @input="$v.editedItem.supported_devices.$touch()"
                        >
                          <template v-slot:prepend-item>
                            <v-list-item ripple @mousedown.prevent @click="toggleDevicesTypes">
                              <v-list-item-action>
                                <v-icon :color="editedItem.supported_devices.length ? 'indigo darken-4' : ''">
                                  {{ icon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>Всі девайси</v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2" />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-file-input
                          v-model="editedItem.file"
                          :rules="rules"
                          :error-messages="fileInputErrors"
                          accept="*"
                          label="Завантажити файл ПЗ"
                          prepend-icon="mdi-chip"
                          show-size
                          truncate-length="30"
                          @blur="$v.editedItem.file.$touch()"
                          @input="$v.editedItem.file.$touch()"
                        />
                        <label>{{ editedItem.filename }}</label>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-checkbox
                          v-model="editedItem.is_available"
                          label="Доступна на терміналі (вимкнено для тестових прошивок)"
                          @change="availableChange"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="save">Так</v-btn>
                  <v-btn text @click="close">Нi</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">ПЗ пристрою буде видалено</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="deleteFirmwareConfirm">Так</v-btn>
                  <v-btn text @click="closeDelete">Нi</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.id="{ item }">
          <router-link
            :to="{ name: 'FirmwareCard', params: { id: currentCompanyId.toString(), firmwareId: item.id.toString() } }"
            class="text-decoration-none"
          >
            {{ item.id }}
          </router-link>
        </template>
        <template v-slot:item.device_type="{ item }">
          <span>{{ getDeviceTitle(item.device_type) }}</span>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <span>{{ item.updated_at | getShortDate }} {{ item.updated_at | getShortTime }}</span>
        </template>
        <template v-slot:item.creator="{ item }">
          <span v-if="item.creator">{{ item.creator.first_name }} {{ item.creator.last_name }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <v-tooltip v-if="item.file" :open-on-focus="false" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  :color="installingFirmwares.find((f) => f.firmware.id === item.id) ? '#28B446' : 'rgba(0, 0, 0, 0.54)'"
                  small
                  v-bind="attrs"
                  @click="installItem(item)"
                  v-on="on"
                >
                  mdi-update</v-icon
                >
              </template>
              <span>Установка</span>
            </v-tooltip>
            <v-tooltip :open-on-focus="false" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" small v-bind="attrs" @click="editItem(item)" v-on="on"> mdi-pencil-outline</v-icon>
              </template>
              <span>Редагувати</span>
            </v-tooltip>
            <v-tooltip :open-on-focus="false" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" small v-bind="attrs" @click="deleteFirmware(item)" v-on="on"> mdi-delete-outline </v-icon>
              </template>
              <span>Видалити</span>
            </v-tooltip>
            <v-tooltip :open-on-focus="false" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" small v-bind="attrs" @click="navigateToFirmwareCard(item)" v-on="on">
                  mdi-eye-outline
                </v-icon>
              </template>
              <span>Переглянути</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetData">Оновити</v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-card v-if="!userRoles.includes(rolesDictionary.ACCOUNTANT)" class="mt-5" flat>
      <v-toolbar elevation="1" tile>
        <v-tabs v-model="logTab">
          <v-tab
            v-for="tab in LOG_TABS"
            :key="tab.name"
            :tab-value="tab.name"
            @click="$router.replace({ query: { logTab: tab.name } })"
          >
            {{ tab.label }}
          </v-tab>
        </v-tabs>
      </v-toolbar>
      <Logs :preselected-event-types="[10, 15]" />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ConvertDate from '@/mixins/convertDate'
import resetTableData from '@/mixins/resetTableData'
import { FIRMWARE_SCHEMA } from '@/const/apiSchemas'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import AxiosApi from '@/services/AxiosApi'
import { mergeObjects } from '@/helpers/mergeObjects'
import Logs from '@/components/common/Logs.vue'
import BackButton from '@/components/common/BackButton.vue'
import SetLogTabs from '@/mixins/setLogTabs'
import cloneDeep from '@/mixins/cloneDeep'
import convertDevicesTypes from '@/mixins/convertDevicesTypes'
import sortUtils from '@/mixins/sortUtils'
import PagingSelect from '@/components/common/PagingSelect.vue'
import AwaitingSearch from '@/mixins/constructor/awaitingSearch'

const api = new AxiosApi()

const isFileValid = (companyId, setErrorMsg) => async (value) => {
  if (!value) return true
  const formData = new FormData()
  formData.append('file', value)
  const payload = { companyId, formData }
  try {
    const res = await api.post(`/api/v1/companies/${payload.companyId}/firmware/file/check`, payload.formData)
    return res.status === 200
  } catch (e) {
    setErrorMsg(e.response ? e.response.message : e.message)
    return false
  }
}

export default {
  name: 'FirmwaresList',
  components: { PagingSelect, BackButton, Logs },

  mixins: [validationMixin, resetTableData, ConvertDate, convertDevicesTypes, SetLogTabs, cloneDeep, sortUtils, AwaitingSearch],

  data: () => ({
    rules: [(value) => !value || value.size < 2000000 || 'Розмір файлу не повинен перевищувати 2 Мб!'],
    headers: [
      {
        text: '# (ID)',
        align: 'left',
        sortable: true,
        value: 'id',
      },
      {
        text: 'Тип пристрою',
        align: 'center',
        sortable: true,
        value: 'device_type',
      },
      {
        text: 'Модель',
        align: 'start',
        sortable: true,
        value: 'terminal_model',
      },
      {
        text: 'Версія ПЗ',
        align: 'start',
        sortable: true,
        value: 'version_name',
      },
      {
        text: 'Які типи автоматів ПЗ підтримує',
        align: 'start',
        sortable: false,
        value: 'supported_devices',
      },
      {
        text: 'Змінено',
        align: 'start',
        sortable: true,
        value: 'updated_at',
      },
      {
        text: 'ПІБ',
        align: 'start',
        sortable: true,
        value: 'creator',
        sortFields: ['creator.lastName', 'creator.firstName'],
      },
      {
        text: 'Опис для користувача',
        align: 'start',
        sortable: false,
        value: 'description',
      },
      {
        text: 'Дія',
        value: 'actions',
        sortable: false,
        width: '130px',
        align: 'center',
      },
    ],
    editedIndex: -1,
    editedItem: null,
    defaultItem: null,
    clickedFirmwareId: null,
    clickedFirmware: {},
    options: {
      sortBy: ['id'],
      sortDesc: [true],
    },
    search: '',
    loading: false,
    firmwareVersionsLoading: false,
    dialog: false,
    dialogDelete: false,
    file: null,
    isDeviceTypeTerminal: true,
    firmwareVersionsOptions: null,
    fileErrorMsg: '',
  }),

  validations() {
    const fileValidator = { required, format: isFileValid(this.currentCompanyId, this.setErrorMsg) }
    return {
      editedItem: {
        device_type: { required },
        terminal_model: { required },
        version_name: { required },
        supported_devices: { required },
        file: this.editedIndex === -1 ? fileValidator : { format: isFileValid(this.currentCompanyId, this.setErrorMsg) },
      },
    }
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.clearData()
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    options: {
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('firmwares', ['firmwares', 'firmwaresMeta', 'firmwaresForSearch', 'installingFirmwares']),
    ...mapState('terminals', ['terminals']),
    ...mapState('dictionaries', ['machinesTypesDictionary', 'terminalsModelsDictionary', 'firmwareDevicesTypes']),
    ...mapGetters('firmwares', ['getFirmwaresTotalCount']),

    formTitle() {
      return this.editedIndex === -1 ? 'Додати ПЗ' : 'Редагувати ПЗ'
    },

    currentCompanyId() {
      return this.$route.params.id
    },

    allDevicesTypesSelected() {
      return this.editedItem.supported_devices.length === this.firmwareDevicesTypes.length
    },

    someDevicesTypesSelected() {
      return this.editedItem.supported_devices.length > 0 && !this.allDevicesTypesSelected
    },

    fileInputErrors() {
      const errors = []
      if (!this.$v.editedItem.file.$dirty) return errors
      if (this.editedIndex === -1) {
        !this.$v.editedItem.file.required && errors.push('Це поле обов"язкове')
      }
      !this.$v.editedItem.file.format && errors.push(this.fileErrorMsg)
      return errors
    },

    icon() {
      if (this.allDevicesTypesSelected) return 'mdi-close-box'
      if (this.someDevicesTypesSelected) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },

  methods: {
    ...mapActions('firmwares', [
      'loadFirmwares',
      'createNewFirmware',
      'updateSelectedFirmware',
      'deleteSelectedFirmware',
      'uploadFirmwareFile',
    ]),
    ...mapActions('terminals', ['loadTerminals']),
    ...mapActions('logs', ['displaySuccessAlert', 'displayErrorAlert']),
    ...mapMutations('firmwares', ['SET_FIRMWARES']),
    ...mapMutations('terminals', ['SET_TERMINALS']),

    inputErrors(fieldName) {
      const errors = []
      if (!this.$v.editedItem[fieldName].$dirty) return errors
      !this.$v.editedItem[fieldName].required && errors.push('Це поле обов"язкове')
      return errors
    },

    setErrorMsg(msg) {
      this.fileErrorMsg = msg
    },

    async initialize() {
      this.editedItem = this.cloneObjectDeep(FIRMWARE_SCHEMA)
      this.defaultItem = this.cloneObjectDeep(FIRMWARE_SCHEMA)
      this.firmwareVersionsOptions = {
        loadingFunction: this.loadFirmwares,
        payload: {
          company: this.currentCompanyId,
          isAvailable: 1,
          forSearch: 1,
          sort: {
            id: 'desc',
          },
        },
      }
      const terminalsPayload = {
        company: this.currentCompanyId,
        showUnconnected: 1,
      }
      await this.loadTerminals(terminalsPayload)
      this.logTab = this.$route.query?.logTab || this.LOG_TABS[0].name
    },

    async paginateTo() {
      if (this.currentCompanyId !== 'undefined') {
        this.loading = true
        const payload = {
          company: this.currentCompanyId,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sort: this.sortObject,
          search: {
            id: this.search,
            versionName: this.search,
          },
        }
        await this.loadFirmwares(payload)
        this.loading = false
      } else {
        await this.displayWarningAlert({ message: 'Оберiть компанiю' })
        await this.$router.replace('/spa')
      }
    },

    async editItem(item) {
      this.editedIndex = this.firmwares.findIndex((firmware) => firmware.id === item.id)
      this.editedItem = mergeObjects(this.editedItem, item)
      this.editedItem.available_to_update = [
        ...item.available_to_update.map(({ id, device_type, version_name }) => ({ id, device_type, version_name })),
      ]
      this.editedItem.filename = this.editedItem.file
      this.editedItem.file = null
      this.clickedFirmwareId = item.id
      this.dialog = true
    },

    close() {
      this.dialog = false
      this.$v.editedItem.$reset()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, { ...FIRMWARE_SCHEMA })
        this.editedIndex = -1
      })
    },

    uploadFile(id) {
      const formData = new FormData()
      formData.append('file', this.file)
      const payload = {
        companyId: this.currentCompanyId,
        firmwareId: id,
        formData,
      }
      return api.post(`/api/v1/companies/${payload.companyId}/firmware/${payload.firmwareId}/upload`, payload.formData)
    },

    availableChange(val) {
      !val && (this.editedItem.available_to_update = [])
    },

    async save() {
      this.file = this.editedItem.file
      this.$v.editedItem.$touch()
      if (this.$v.$anyError) return
      this.loading = true
      this.editedItem.available_to_update = [...this.editedItem.available_to_update.map((item) => ({ id: item.id }))]
      if (this.editedIndex > -1) {
        const payload = {
          companyId: this.currentCompanyId,
          firmwareId: this.clickedFirmwareId,
          updatedFirmware: { ...this.editedItem },
        }
        payload.updatedFirmware.file = this.editedItem.filename
        delete payload.updatedFirmware.filename
        try {
          await this.updateSelectedFirmware(payload)
          if (this.file) {
            try {
              await this.uploadFile(payload.firmwareId)
              await this.displaySuccessAlert({
                message: 'ПЗ завантажено успішно!',
              })
            } catch (e) {
              await this.displayErrorAlert({
                data: { message: 'Помилка завантаження файла ПЗ!' },
              })
            }
          }
        } catch (e) {
          await this.displayErrorAlert({
            data: { message: 'Помилка збереження ПЗ!' },
          })
        } finally {
          this.close()
          await this.paginateTo()
          this.loading = false
        }
      } else {
        const payload = {
          companyId: this.currentCompanyId,
          newFirmware: this.editedItem,
        }
        delete payload.newFirmware.file
        try {
          const res = await api.post(`/api/v1/companies/${this.currentCompanyId}/firmware/`, this.editedItem)
          const id = res.data?.data?.id || null
          await this.displaySuccessAlert({
            message: 'ПЗ для пристрою створено успішно!',
          })
          if (id) {
            try {
              await this.uploadFile(id)
              await this.displaySuccessAlert({
                message: 'ПЗ завантажено успішно!',
              })
            } catch (e) {
              const deletePayload = {
                companyId: this.currentCompanyId,
                firmwareId: id,
              }
              await this.deleteSelectedFirmware(deletePayload)
              await this.displayErrorAlert({
                data: {
                  message: 'Помилка завантаження файла ПЗ! Запис видалено.',
                },
              })
            }
          }
        } catch (e) {
          await this.displayErrorAlert({
            data: { message: 'Помилка збереження ПЗ!' },
          })
        } finally {
          this.close()
          await this.paginateTo()
          this.loading = false
        }
      }
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    deleteFirmware(item) {
      this.clickedFirmwareId = item.id
      this.dialogDelete = true
    },

    async deleteFirmwareConfirm() {
      this.loading = true
      const payload = {
        companyId: this.currentCompanyId,
        firmwareId: this.clickedFirmwareId,
      }
      await this.deleteSelectedFirmware(payload)
      await this.paginateTo()
      this.loading = false
      this.closeDelete()
    },

    navigateToFirmwareCard(item) {
      this.$router.push({
        name: 'FirmwareCard',
        params: {
          id: this.currentCompanyId.toString(),
          firmwareId: item.id.toString(),
        },
      })
    },

    installItem(item) {
      this.$router.push({
        name: 'FirmwareCard',
        params: {
          id: this.currentCompanyId.toString(),
          firmwareId: item.id.toString(),
        },
        query: { installMode: 1 },
      })
    },

    clearData() {
      this.SET_FIRMWARES([])
      this.SET_TERMINALS([])
    },

    toggleDevicesTypes() {
      this.$nextTick(() => {
        if (this.allDevicesTypesSelected) {
          this.editedItem.supported_devices = []
        } else {
          this.editedItem.supported_devices = this.firmwareDevicesTypes.map((item) => item.name).slice()
        }
      })
    },
  },
}
</script>
